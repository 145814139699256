import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { AssumptionId } from 'businessLogic/aggregatorAssumptions';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import FormFooter from 'components/FormFooter';
import NameInput from 'components/NameInput';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import { addItem } from 'helpers/arrayHelper';
import { ProductId } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { filterTitleOptions } from 'helpers/filterTitleOptions';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  useAssumptionsAgreement,
  useTouchAssumption,
} from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import {
  initialJointPolicyholderDetails,
  usePolicyDetails,
} from 'state/formData/policyDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import useReferenceData from 'state/referenceData/useReferenceData';
import CoverStartDateQuestion from './CoverStartDateQuestion';
import useAboutYourPolicyQuestions from './questions';
import {
  LargeRichText,
  QuestionFieldWithNoBottomMargin,
  StyledRemoveButton,
  WarningPanelWithMarginBottom,
} from './styles';
import useAboutYourPolicyRules from './validation';
import SectionHeading from '../SectionHeading';

type AboutYourPolicyFormProps = {
  moveNext: () => void;
  moveBack: () => void;
};

type AboutYourPolicyFormData = {
  csPetAboutYourPolicy: {
    next_button_text: string;
    joint_policyholder_section_heading: string;
    joint_policyholder_introduction: string;
    joint_policyholder_warning_panel: string;
    add_joint_policyholder_button_text: string;
    remove_joint_policyholder_button_text: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      next_button_text
      joint_policyholder_section_heading
      joint_policyholder_introduction
      joint_policyholder_warning_panel
      add_joint_policyholder_button_text
      remove_joint_policyholder_button_text
    }
  }
`;

const AboutYourPolicyForm: React.FC<AboutYourPolicyFormProps> = ({
  moveNext,
  moveBack,
}) => {
  const {
    csPetAboutYourPolicy: {
      next_button_text,
      joint_policyholder_section_heading,
      joint_policyholder_introduction,
      joint_policyholder_warning_panel,
      add_joint_policyholder_button_text,
      remove_joint_policyholder_button_text,
    },
  } = useStaticQuery<AboutYourPolicyFormData>(query);

  const questions = useAboutYourPolicyQuestions();
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const [customerDetails] = useCustomerDetails();
  const [petsDetails, updatePetsDetails] = usePetsDetails();

  const numberOfPetsInQuote = petsDetails.length;

  const quote = useCurrentQuote();
  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  const [minDate, isMinDateInFuture] = useFirstEligibleStartDateFromToday();

  // If any of the Pets are too young at the current date, we restrict the date picker
  // to ensure they are valid at the cover start date and display a special message.
  const [displayYoungPetMessage, setDisplayYoungPetMessage] = useState(false);

  // A warning panel is displayed when the customer enters their own details in the joint policyholder form
  const displayJointPolicyholderWarningPanel =
    customerDetails.customerFirstName !== '' &&
    customerDetails.customerFirstName === policyDetails.jointPolicyholderFirstName &&
    customerDetails.customerLastName !== '' &&
    customerDetails.customerLastName === policyDetails.jointPolicyholderLastName &&
    !areDateValuesEqual(customerDetails.customerDob, initialDateValue) &&
    areDateValuesEqual(customerDetails.customerDob, policyDetails.jointPolicyholderDob);

  useEffect(() => {
    setDisplayYoungPetMessage(isMinDateInFuture);
  }, [isMinDateInFuture]);

  const policyDetailsRules = useAboutYourPolicyRules();

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings(
    policyDetails,
    policyDetailsRules.errors,
    policyDetailsRules.warnings
  );

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();
  const touchAssumption = useTouchAssumption();

  const assumptionIdToFormFieldValidationMapping: Partial<Record<
    AssumptionId,
    () => void
  >> = {
    one_pet_in_household: () => showValidation('numberOfPetsInHousehold'),
    two_pets_in_household: () => showValidation('numberOfPetsInHousehold'),
  };

  useEffect(() => {
    if (assumptions !== undefined && !assumptionsAgreement.assumptionsAgreed) {
      assumptions.assumptions.forEach(({ id }) =>
        assumptionIdToFormFieldValidationMapping[id]?.()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    policyDetails.jointPolicyholderTitle
  );

  const explanatoryText = displayYoungPetMessage
    ? questions.coverStartDate.youngPetExplanatoryText
    : questions.coverStartDate.explanatoryText;

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <QuestionFieldWithNoBottomMargin
        question={questions.numberOfPetsInHousehold.question}
        errorText={getError('numberOfPetsInHousehold')}
        warningText={getWarning('numberOfPetsInHousehold')}>
        <SegmentedSelector
          id="numberOfPetsInHousehold"
          value={policyDetails.numberOfPetsInHousehold?.toString()}
          shownOptions={5}
          options={[
            {
              name: '1',
              value: '1',
            },
            {
              name: '2',
              value: '2',
            },
            {
              name: '3',
              value: '3',
            },
            {
              name: '4',
              value: '4',
            },
            {
              name: '5',
              value: '5',
            },
            {
              name: '6',
              value: '6',
            },
            {
              name: '7',
              value: '7',
            },
            {
              name: '8',
              value: '8',
            },
            {
              name: '9',
              value: '9',
            },
            {
              name: '10+',
              value: '10',
            },
          ]}
          onChange={(event) => {
            touchAssumption([
              'one_pet_in_household',
              'two_pets_in_household',
              'three_pets_in_household',
            ]);
            updatePolicyDetails({
              numberOfPetsInHousehold: Number(event.target.value),
            });
            trackRadioButtonClick('Number of pets in household', event.target.value);
            showValidation('numberOfPetsInHousehold');
          }}
        />
      </QuestionFieldWithNoBottomMargin>
      {policyDetails.numberOfPetsInHousehold &&
        numberOfPetsInQuote < 3 &&
        policyDetails.numberOfPetsInHousehold > numberOfPetsInQuote && (
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
              <PromotionalBox
                pageTitle={PageTitle.AboutYourPolicy}
                id="number-of-pets-promotional-box"
                icon={
                  unwrapSingleton(questions.numberOfPetsInHousehold.promotionalBox.icon)
                    ?.icon_code
                }
                heading={questions.numberOfPetsInHousehold.promotionalBox.heading}
                information={questions.numberOfPetsInHousehold.promotionalBox.information}
                button={{
                  buttonText:
                    questions.numberOfPetsInHousehold.promotionalBox.add_pet_button_text,
                  screenReaderText:
                    questions.numberOfPetsInHousehold.promotionalBox
                      .add_pet_button_screenreader_text,
                  onClick: () => {
                    updatePetsDetails(addItem(petsDetails, initialPet));
                    /* This use of setTimeout forces scrollAndFocusInput to be called after
                     * updatePetsDetails, so that we attempt to scroll to the newly added pet
                     * form section *after* the section has been rendered in the DOM.
                     *
                     * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
                     * details about this implementation.
                     */
                    navigate(quoteAndBuyRoutes.aboutYourPet);
                    setTimeout(() =>
                      scrollAndFocusInput(`petsDetails[${petsDetails.length}]`)
                    );
                    trackTextButtonClick(
                      PageTitle.AboutYou,
                      questions.numberOfPetsInHousehold.promotionalBox.add_pet_button_text
                    );
                  },
                }}
              />
            </GridItem>
          </Grid>
        )}
      <CoverStartDateQuestion
        explanatoryText={explanatoryText}
        questionText={questions.coverStartDate.questionText}
        minDate={minDate}
        onChangeCallback={() => setDisplayYoungPetMessage(false)}
      />
      <SectionHeading heading={joint_policyholder_section_heading} />
      {joint_policyholder_introduction && (
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <LargeRichText html={joint_policyholder_introduction} />
          </GridItem>
        </Grid>
      )}
      {!policyDetails.includeJointPolicyholder && (
        <AddButton
          id="addJointPolicyholder"
          onClick={() => {
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Add joint policy holder');
            updatePolicyDetails({
              ...initialJointPolicyholderDetails,
              includeJointPolicyholder: true,
            });
          }}>
          {add_joint_policyholder_button_text}
        </AddButton>
      )}
      {policyDetails.includeJointPolicyholder && (
        <>
          <QuestionField
            question={questions.jointPolicyholderTitle}
            errorText={getError('jointPolicyholderTitle')}>
            <SelectInput
              id="jointPolicyholderTitle"
              value={policyDetails.jointPolicyholderTitle}
              options={filteredTitlesRefData}
              placeholder={questions.jointPolicyholderTitle.placeholder}
              onChange={(e) => {
                trackFormDropdownSelect('jointPolicyholderTitle', e.target.value);
                updatePolicyDetails({
                  jointPolicyholderTitle: e.target.value,
                });
              }}
              onBlur={() => showValidation('jointPolicyholderTitle')}
              onFocus={trackFormDropdownFocus(
                'jointPolicyholderTitle',
                PageTitle.AboutYourPolicy
              )}
              loading={!filteredTitlesRefData}
            />
          </QuestionField>
          <QuestionField
            question={questions.jointPolicyholderFirstName}
            errorText={getError('jointPolicyholderFirstName')}>
            <NameInput
              id="jointPolicyholderFirstName"
              placeholder={questions.jointPolicyholderFirstName.placeholder}
              maxLength={40}
              value={policyDetails.jointPolicyholderFirstName}
              onChange={(e) => {
                if (e.target.value.match(INPUT_REGEX_NAME)) {
                  updatePolicyDetails({
                    jointPolicyholderFirstName: capitaliseCharacterAfterHyphenAndSpace(
                      e.target.value
                    ),
                  });
                }
              }}
              onBlur={() => showValidation('jointPolicyholderFirstName')}
              onFocus={trackFormTextFieldFocus('jointPolicyholderFirstName')}
            />
          </QuestionField>
          <QuestionField
            question={questions.jointPolicyholderLastName}
            errorText={getError('jointPolicyholderLastName')}>
            <NameInput
              id="jointPolicyholderLastName"
              placeholder={questions.jointPolicyholderLastName.placeholder}
              maxLength={30}
              value={policyDetails.jointPolicyholderLastName}
              onChange={(e) => {
                if (e.target.value.match(INPUT_REGEX_NAME)) {
                  updatePolicyDetails({
                    jointPolicyholderLastName: capitaliseCharacterAfterHyphenAndSpace(
                      e.target.value
                    ),
                  });
                }
              }}
              onBlur={() => showValidation('jointPolicyholderLastName')}
              onFocus={trackFormTextFieldFocus('jointPolicyholderLastName')}
            />
          </QuestionField>
          <QuestionField
            question={questions.jointPolicyholderDob}
            errorText={getError('jointPolicyholderDob')}>
            <DateInput
              id="jointPolicyholderDob"
              value={policyDetails.jointPolicyholderDob}
              onChange={(e) =>
                updatePolicyDetails({
                  jointPolicyholderDob: e,
                })
              }
              onBlur={() => {
                updatePolicyDetails({
                  jointPolicyholderDob: addLeadingZerosToDateValue(
                    policyDetails.jointPolicyholderDob
                  ),
                });
                showValidation('jointPolicyholderDob');
              }}
              onFocus={trackFormTextFieldFocus('jointPolicyholderDob')}
            />
          </QuestionField>
          {displayJointPolicyholderWarningPanel && (
            <FieldGrid alignLeft>
              <WarningPanelWithMarginBottom data-cy="jointPolicyholder-warningPanel">
                {joint_policyholder_warning_panel}
              </WarningPanelWithMarginBottom>
            </FieldGrid>
          )}
          <StyledRemoveButton
            id="removeJointPolicyholder"
            onClick={() => {
              trackTextButtonClick(
                PageTitle.AboutYourPolicy,
                'Remove joint policy holder'
              );
              updatePolicyDetails(initialJointPolicyholderDetails);
            }}>
            {remove_joint_policyholder_button_text}
          </StyledRemoveButton>
        </>
      )}
      {!isAggsQuote && (
        <KeepingYouInformedForm
          formValidation={{ getError, showValidation }}
          pageTitle={PageTitle.AboutYourPolicy}
        />
      )}
      <FormFooter
        contentColumns={{ desktop: 6, tabletLandscape: 6 }}
        moveNextButton={{
          text: next_button_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Submit details'),
        }}
        backButton={{
          onClick: () => {
            moveBack();
            trackTextButtonClick(PageTitle.AboutYourPolicy, 'Move back');
          },
        }}
        pageTitle={PageTitle.AboutYourPolicy}
      />
    </form>
  );
};

export default AboutYourPolicyForm;
