import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  border: none;
  margin-top: ${spacing(1)};
  padding: ${spacing(4)};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
`;

export const Heading = styled.p`
  ${fonts.headingSmall};
  margin: 0 0 ${spacing(2)};
`;

export const AddButtonWithTopMargin = styled(AddButton)`
  margin-top: ${spacing(3)};
`;
