import {
  addYearsToDate,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationErrorAndWarningRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { validAssumptionsIncludeId } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { ProductId } from 'helpers/businessConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { usePetsDetails } from 'state/formData/petsDetails';
import { PolicyDetails } from 'state/formData/policyDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsAboutYourPolicyErrorMessages = {
  csPetAboutYourPolicyQuestions: {
    joint_policyholder_title: CsErrorsMissingOnly;
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
    keeping_you_informed: CsErrorsMissingOnly;
    number_of_pets_in_household: {
      error_messages: {
        missing: string;
        less_than_pets_on_policy: string;
      };
    };
  };
  csPetAggregators: {
    assumptions: {
      one_pet_in_household: {
        warning: string;
      };
      two_pets_in_household: {
        warning: string;
      };
      three_pets_in_household: {
        warning: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      joint_policyholder_title {
        error_messages {
          missing
        }
      }
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
      keeping_you_informed {
        error_messages {
          missing
        }
      }
      number_of_pets_in_household {
        error_messages {
          missing
          less_than_pets_on_policy
        }
      }
    }
    csPetAggregators {
      assumptions {
        one_pet_in_household {
          warning
        }
        two_pets_in_household {
          warning
        }
        three_pets_in_household {
          warning
        }
      }
    }
  }
`;

const useAboutYourPolicyRules = (): ValidationErrorAndWarningRules<PolicyDetails> => {
  const errorMessages = useStaticQuery<CsAboutYourPolicyErrorMessages>(query);

  const [petsDetails] = usePetsDetails();

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const jointPolicyholderIncluded = (data: PolicyDetails): boolean =>
    !!data.includeJointPolicyholder;

  const quote = useCurrentQuote();
  const isDirectQuote = (): boolean => quote.productId === ProductId.DIRECT;

  return {
    errors: {
      jointPolicyholderTitle: validateIf(jointPolicyholderIncluded, [
        required(
          errorMessages.csPetAboutYourPolicyQuestions.joint_policyholder_title
            .error_messages.missing
        ),
      ]),
      jointPolicyholderFirstName: validateIf(jointPolicyholderIncluded, [
        required(
          errorMessages.csPetAboutYourPolicyQuestions.first_name.error_messages.missing
        ),
      ]),
      jointPolicyholderLastName: validateIf(jointPolicyholderIncluded, [
        required(
          errorMessages.csPetAboutYourPolicyQuestions.last_name.error_messages.missing
        ),
      ]),
      jointPolicyholderDob: validateIf(jointPolicyholderIncluded, [
        requiredDateValue(
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages.missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .invalid_month
        ),
        dateValueValid(
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .invalid_date
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .year_too_short
        ),
        dateValueLessThanOrEqualTo(
          new Date(),
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .date_in_future
        ),
        dateValueLessThanOrEqualTo(
          addYearsToDate(new Date(), -18),
          errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
            .too_young
        ),
        {
          validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
          errorMessage:
            errorMessages.csPetAboutYourPolicyQuestions.date_of_birth.error_messages
              .invalid_date,
        },
      ]),
      keepingYouInformed: validateIf(isDirectQuote, [
        required(
          errorMessages.csPetAboutYourPolicyQuestions.keeping_you_informed.error_messages
            .missing
        ),
      ]),
      numberOfPetsInHousehold: [
        required(
          errorMessages.csPetAboutYourPolicyQuestions.number_of_pets_in_household
            .error_messages.missing
        ),
        {
          validityCondition: (value) => !!value && petsDetails.length <= value,
          errorMessage:
            errorMessages.csPetAboutYourPolicyQuestions.number_of_pets_in_household
              .error_messages.less_than_pets_on_policy,
        },
      ],
    },
    warnings: {
      numberOfPetsInHousehold: [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            validAssumptionsIncludeId(assumptions, 'one_pet_in_household'),
          {
            validityCondition: (value) => value !== 1,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.one_pet_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            validAssumptionsIncludeId(assumptions, 'two_pets_in_household'),
          {
            validityCondition: (value) => value !== 2,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.two_pets_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            validAssumptionsIncludeId(assumptions, 'three_pets_in_household'),
          {
            validityCondition: (value) => value !== 3,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.three_pets_in_household.warning,
          }
        ),
      ],
    },
  };
};

export default useAboutYourPolicyRules;
