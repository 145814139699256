import RemoveButton from '@rsa-digital/evo-shared-components/components/RemoveButton';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import { WarningPanel } from 'components/StatusPanel';

export const WarningPanelWithMarginBottom = styled(WarningPanel)`
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const StyledRemoveButton = styled(RemoveButton)`
  margin-top: ${spacing(1)};

  ${mediaQuery.tabletLandscape`
     margin-top: 0;
  `}
`;

export const LargeRichText = styled(RichText)`
  ${fonts.paragraphLarge};
  margin-bottom: ${spacing(3)};
`;

export const QuestionFieldWithNoBottomMargin = styled(QuestionField)`
  margin-bottom: 0;
`;
