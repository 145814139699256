import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYourPolicyForm from 'forms/AboutYourPolicyForm';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CsHero } from 'types/contentStack';

type AboutYourPolicyProps = {
  data: {
    csPetAboutYourPolicy: {
      meta_title: string;
      hero: CsHero;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csPetAboutYourPolicy {
      meta_title
      hero {
        heading
        subheading
      }
    }
  }
`;

const AboutYourPolicy: React.FC<AboutYourPolicyProps> = ({
  data: {
    csPetAboutYourPolicy: { hero, meta_title },
  },
  location,
}) => {
  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote);
  };

  const moveBack = (): void => {
    navigate(quoteAndBuyRoutes.aboutYou);
  };

  usePageTracking(meta_title);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <Layout
        pageTitle={PageTitle.AboutYourPolicy}
        currentStep={QuoteAndBuyStep.AboutYourPolicy}
        metaTitle={meta_title}>
        <HeroBanner heading={hero.heading} subheading={hero.subheading} />
        <AboutYourPolicyForm moveNext={moveNext} moveBack={moveBack} />
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYourPolicy;
